<template>
  <Main>
    <div class="replenishment" v-if="translateData">
      <div class="replenishment__container">
        <div class="replenishment__title">Ошибка</div>
        <div class="replenishment__text">В процессе пополнения кошелька произошла ошибка.Обратитесь в поддержку</div>
      </div>
    </div>
    <RefLink />
  </Main>
</template>

<script>
import { defineComponent } from 'vue'

import Main from '@/templates/Main.vue'
import RefLink from '@/components/molecules/RefLink.vue'

export default defineComponent({
  name: 'ReplenishmentError',
  data () {
    return {
      translateData: {}
    }
  },
  components: {
    Main,
    RefLink
  },
  mounted () {
    // this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push()
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
</script>

<style lang="scss" scoped>
  .replenishment {
    padding: 0 5%;
    &__container {
      max-width: 650px;
      margin: 150px auto 0;
      background: #15181B;
      border-radius: 20px;
      padding: 150px 50px;
    }

    &__title {
      font-size: 48px;
      font-weight: 700;
      color: #FF444F;
    }

    &__text {
      padding-top: 10px;
      font-size: 24px;
      color: #fff;
    }
  }

  @media (max-width: $break-sm) {
    .replenishment {

      &__container {
        margin-top: 70px;
        padding: 50px 20px;
      }

      &__title {
        font-size: 24px;
      }

      &__text {
        font-size: 18px;
      }
    }
  }
</style>
